import { useState, useRef, useEffect, useMemo } from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';
import { VariableSizeList } from 'react-window';

import { filterPanelSettings, FilterPanelSetting } from '@constants/filterPanelSettings';
import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { generateFilterItemList } from '@utils/FilterItemsUtils';
import { FilterChipsList } from './Components/FilterChipsList/FilterChipsList';
import FilterItem from './Components/FilterItem/FilterItem';
import FilterOptionsList from './Components/FilterOptionsList/FilterOptionsList';
import { NonReferenceDataFilterOptions } from './Components/NonReferenceDataFilterOptions/NonReferenceDataFilterOptions';
import SearchBlock from './Components/SearchBlock/SearchBlock';
import { useStyles } from './FilterItemsSelectorStyles';

interface IProps {
    readonly filterOptions: FilterOption[];
    readonly selectedFilterOptions: FilterOption[];
    readonly filterType: SupplyFilterType;
    readonly showCount: boolean;
    readonly showFilterOptionCode: boolean;
    readonly filterChanged: (event: FilterOption[]) => void;
    readonly isLoading?: boolean;
}

export function FilterItemsSelector({
    filterOptions,
    selectedFilterOptions,
    filterType,
    showCount,
    showFilterOptionCode,
    filterChanged,
    isLoading,
}: IProps) {
    const { isBreakpointSmall } = useBreakpointContext();
    const [currentItems, setCurrentItems] = useState<FilterOption[]>(selectedFilterOptions);
    const [search, setSearch] = useState<string>('');
    const enableGrouping = filterPanelSettings[filterType]?.includes(FilterPanelSetting.enableGrouping);
    const showSearchToolbar = filterPanelSettings[filterType]?.includes(FilterPanelSetting.showSearchToolbar);
    const showCharacterButtonToolbar =
        filterPanelSettings[filterType]?.includes(FilterPanelSetting.showCharacterButtonToolbar) && !isBreakpointSmall;

    const showChips = filterPanelSettings[filterType]?.includes(FilterPanelSetting.showChips);
    const useAlphabeticalSorting = filterPanelSettings[filterType]?.includes(FilterPanelSetting.useAlphabeticalSorting);

    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'filterItemsSelector' });
    const variableSizeListRef = useRef<VariableSizeList>(null);

    if (useAlphabeticalSorting) {
        filterOptions.sort((a, b) => a.name.localeCompare(b.name));
    }

    const removedOptions = selectedFilterOptions.filter(
        // Specifically check on the key and not the entire item because the value is empty on selected options
        (selectedItem: FilterOption) => filterOptions.findIndex(a => a.key === selectedItem.key) === -1,
    );
    const sortedFilterOptions = useMemo<FilterOption[]>(filterData, [filterOptions, search]);

    const filterItems = generateFilterItemList(sortedFilterOptions, enableGrouping, isBreakpointSmall);

    const allHeaders = filterItems.reduce(
        (headers, currentItem, currentIndex) => {
            if (currentItem.header) headers.push({ key: currentItem.header.key, index: currentIndex });
            return headers;
        },
        [] as { key: string; index: number }[],
    );

    function isSelected(item: FilterOption) {
        return !!currentItems?.find(currentItem => currentItem.key === item.key);
    }

    function jumpToIndex(index: number) {
        variableSizeListRef?.current?.scrollToItem(index, 'start');
    }

    function filterData() {
        return search
            ? filterOptions.filter(
                  option =>
                      option.name.toLowerCase().includes(search.toLowerCase()) ||
                      option.code?.toLowerCase().includes(search.toLowerCase()),
              )
            : filterOptions;
    }

    function toggleChange(item: FilterOption, checked: boolean) {
        const updatedCurrentItems = checked
            ? [...currentItems, item]
            : currentItems.filter((currentItem: FilterOption) => currentItem.key !== item.key);

        setCurrentItems(updatedCurrentItems);
        filterChanged(updatedCurrentItems);
    }

    useEffect(() => {
        jumpToIndex(0);
    }, [filterOptions, search]);

    useEffect(() => {
        // Resets index and forces re-render of items. This was added because header items wouldn't render with correct height due to react-window's caching of the sizes of each row
        variableSizeListRef?.current?.resetAfterIndex(0, true);
    }, [sortedFilterOptions]);

    useEffect(() => {
        setCurrentItems(selectedFilterOptions);
    }, [selectedFilterOptions]);

    return (
        <Grid container rowSpacing={2} className={classes.filterContainer}>
            {!isLoading && removedOptions.length > 0 && (
                <Grid size={{ xs: 12 }}>
                    <NonReferenceDataFilterOptions options={removedOptions} />
                </Grid>
            )}
            {(showSearchToolbar || showCharacterButtonToolbar) && (
                <SearchBlock
                    headerCharacters={allHeaders}
                    onSearchChange={setSearch}
                    jumpToIndex={jumpToIndex}
                    showSearchToolbar={showSearchToolbar}
                    showCharacterButtonToolbar={showCharacterButtonToolbar}
                />
            )}
            {(enableGrouping || showChips) && (
                <>
                    <FilterOptionsList
                        filterItems={filterItems}
                        currentItems={currentItems}
                        showCount={showCount}
                        showFilterOptionCode={showFilterOptionCode}
                        toggleChange={toggleChange}
                        filterType={filterType}
                        isLoading={isLoading}
                        noResults={filterOptions.length === 0 || (sortedFilterOptions.length === 0 && !!search)}
                        ref={variableSizeListRef}
                    />
                    {showChips && (
                        <FilterChipsList
                            allSortedFilterOptions={sortedFilterOptions}
                            filterOptions={currentItems}
                            filterType={filterType}
                            onDelete={key => {
                                const updatedList = currentItems.filter(item => item.key !== key);
                                setCurrentItems(updatedList);
                                filterChanged(updatedList);
                            }}
                            showCode={showFilterOptionCode}
                        />
                    )}
                </>
            )}
            {!enableGrouping && !showChips && (
                <Grid container size={{ xs: 12 }} gap={0} className={classes.variableSizeContainer}>
                    {(filterOptions.length === 0 || sortedFilterOptions.length === 0) && (
                        <Typography>{t('noResult')}</Typography>
                    )}
                    {sortedFilterOptions.map((item: FilterOption) => (
                        <FilterItem
                            key={item.key}
                            item={item}
                            selected={isSelected(item)}
                            showCount={showCount}
                            showFilterOptionCode={showFilterOptionCode}
                            onToggle={toggleChange}
                            filterType={filterType}
                        />
                    ))}
                </Grid>
            )}
        </Grid>
    );
}
