import { createContext, useContext, ReactNode, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';

interface IFeatureFlagsContextValue {
    kvvVisible: boolean;
    markingAutomationsVisible: boolean;
}

interface IProps {
    readonly children?: ReactNode;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContextValue>({
    kvvVisible: false,
    markingAutomationsVisible: false,
});

const client = AuctionPreparationClient.instance.getApiClient();

export function FeatureFlagsProvider({ children }: IProps) {
    const kvvVisible = false;

    function getMarkingAutomationFeatureToggle() {
        return client.getMarkingAutomationFeatureToggle();
    }

    const { data: userAllowedForMarkingAutomation } = useQuery(
        ['marking-automation-feature-toggle'],
        () => authenticatedRequest(getMarkingAutomationFeatureToggle),
        {
            onError: error => {
                handleApiGetError(error);
            },
        },
    );

    const value = useMemo<IFeatureFlagsContextValue>(
        () => ({ kvvVisible, markingAutomationsVisible: userAllowedForMarkingAutomation ?? false }),
        [userAllowedForMarkingAutomation],
    );

    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlags(): IFeatureFlagsContextValue {
    return useContext(FeatureFlagsContext);
}
