import { SupplyFilterType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';

export enum FilterPanelSetting {
    enableGrouping,
    showSearchToolbar,
    showCharacterButtonToolbar,
    showChips,
    useAlphabeticalSorting,
    showFilterOptionsCode,
    showMainGroupIcon,
    isRangeFilter,
    isInlineFilter,
}

const {
    enableGrouping,
    showSearchToolbar,
    showCharacterButtonToolbar,
    showChips,
    useAlphabeticalSorting,
    showFilterOptionsCode,
    showMainGroupIcon,
    isRangeFilter,
    isInlineFilter,
} = FilterPanelSetting;

export const mainColorPanels = [SupplyFilterType.MainColor];

export const filterPanelSettings: Record<SupplyFilterType, FilterPanelSetting[]> = {
    [SupplyFilterType.AuctionLocation]: [],
    [SupplyFilterType.Certificates]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
    ],
    [SupplyFilterType.Clock]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
    ],
    [SupplyFilterType.CountryOfOrigin]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
    ],
    [SupplyFilterType.FlowerStemLength]: [showSearchToolbar, showChips, isRangeFilter],
    [SupplyFilterType.MainColor]: [],
    [SupplyFilterType.MainGroup]: [showMainGroupIcon, isInlineFilter],
    [SupplyFilterType.Marking]: [useAlphabeticalSorting, isInlineFilter],
    [SupplyFilterType.MaturityStage]: [showSearchToolbar, showChips],
    [SupplyFilterType.MinimumBranchesPerPlant]: [showSearchToolbar, showChips],
    [SupplyFilterType.Package]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
        showFilterOptionsCode,
    ],
    [SupplyFilterType.PlantHeight]: [showSearchToolbar, showChips],
    [SupplyFilterType.PotSize]: [showSearchToolbar, showChips],
    [SupplyFilterType.Presale]: [useAlphabeticalSorting, isInlineFilter],
    [SupplyFilterType.Product]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
        showFilterOptionsCode,
    ],
    [SupplyFilterType.ProductGroup]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
        showFilterOptionsCode,
        showMainGroupIcon,
    ],
    [SupplyFilterType.Quality]: [useAlphabeticalSorting],
    [SupplyFilterType.Supplier]: [
        enableGrouping,
        showSearchToolbar,
        showCharacterButtonToolbar,
        showChips,
        useAlphabeticalSorting,
        showFilterOptionsCode,
    ],
    [SupplyFilterType.Weight]: [showSearchToolbar, showChips],
};
