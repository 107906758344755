import { useEffect, useRef, useState } from 'react';
import { AddIcon, CheckIcon, DeleteIcon, EditIcon, StarFilledIcon, StarIcon } from '@floriday/floriday-icons';
import {
    ContextMenu,
    contextMenuHandler,
    ContextMenuItem,
    IContextMenuExposedMethods,
    ResponsiveButton,
    ResponsiveTable,
    SnackbarManager,
} from '@floriday/floriday-ui';
import { AutoFixNormal, AutoFixOff } from '@mui/icons-material';
import { IconButton, Paper, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
    ActionResult,
    FilterSet,
    UserSetting,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { handleApiGetError } from '@api/helper';
import config from '@config';
import DeleteFilterSetDialog from '@features/Filtering/Components/Dialogs/DeleteFilterSetDialog/DeleteFilterSetDialog';
import { useFilterSets } from '@features/Filtering/Hooks/useFilterSets';
import OverlayLoadingPage from '@root/Components/OverlayLoadingPage/OverlayLoadingPage';
import PageHeader from '@root/Components/PageHeader/PageHeader';
import { namespaces } from '@root/i18n';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { useSearchCommandContext } from '@store/Providers/SearchCommandProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import { sharedStyles } from '@utils/SharedStyles';
import { getFilterSetMessagesTranslation, getMarkingAutomationMessagesTranslation } from '@utils/TranslationsUtils';
import { useStyles } from './FilterSetOverviewStyles';

export default function FilterSetOverview() {
    const { t } = useTranslation([namespaces.general, namespaces.supply]);
    const { markingAutomationsVisible } = useFeatureFlags();
    const client = AuctionPreparationClient.instance.getApiClient();

    const { classes } = useStyles();
    const { classes: sharedClasses } = sharedStyles();
    const navigate = useNavigate();
    const [selectedFilterSet, setSelectedFilterSet] = useState<FilterSet | null>();
    const [openDeleteFilterSetDialog, setOpenDeleteFilterSetDialog] = useState(false);
    const [defaultFilterSetId, setDefaultFilterSetId] = useState<string | undefined>('');
    const contextMenuRef = useRef<IContextMenuExposedMethods>(null);
    const { lastSearchParams } = useSearchCommandContext();
    const { trackEvent } = useIntercom();
    const { isLoading, refetch, data: filterSets } = useFilterSets();
    const { userSettings, isInitialized, updateUserSettings } = useUserSettings();

    function goBack() {
        navigate({ pathname: '/supply-overview', search: `?${lastSearchParams}` });
    }

    function navigateToFilterSetDetailPage(filterSetId?: string) {
        navigate(`/filter-sets/${filterSetId ?? 'new'}`);
    }

    const deleteFilterSet = useMutation((filtsetId: string) => client.removeFilterSet(filtsetId), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: data => {
            onCloseDeleteFilterSetDialog();
            refetch();

            showFilterSetMessages(data);
        },
    });

    function showFilterSetMessages(filterSetResult?: ActionResult) {
        if (filterSetResult?.isSuccessful) {
            SnackbarManager.show(getFilterSetMessagesTranslation('filterSetDeleted'));
            return;
        }

        if (filterSetResult?.errorCodes && filterSetResult?.errorCodes.length > 0) {
            SnackbarManager.showError(
                filterSetResult?.errorCodes.map(errorCode => getFilterSetMessagesTranslation(errorCode)).join(' '),
            );

            return;
        }

        SnackbarManager.showError(getMarkingAutomationMessagesTranslation('technicalException'));
    }

    function deleteSelectedFilterSet() {
        if (selectedFilterSet?.id) {
            deleteFilterSet.mutate(selectedFilterSet?.id);
        }
    }

    function onOpenDeleteFilterSetDialog(filterset: FilterSet) {
        setSelectedFilterSet(filterset);
        setOpenDeleteFilterSetDialog(true);
    }

    function onCloseDeleteFilterSetDialog() {
        setOpenDeleteFilterSetDialog(false);
    }

    function onSetDefault(filterSetId: string | undefined) {
        const updatedUserSettings: UserSetting = {
            ...userSettings,
            defaultFilterSetId: filterSetId,
        };
        updateUserSettings(updatedUserSettings);
        setDefaultFilterSetId(filterSetId);
    }

    const saveMarkingAutomation = useMutation((filterSetId: string) => client.saveMarkingAutomation(filterSetId), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: () => {
            refetch();
        },
    });

    async function onSaveAutomaticMarking(filterSetId: string) {
        const result = await saveMarkingAutomation.mutateAsync(filterSetId);
        showMarkingAutomationMessages(result);
    }

    const removeMarkingAutomation = useMutation((filterSetId: string) => client.removeMarkingAutomation(filterSetId), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: data => {
            refetch();
            return data;
        },
    });

    async function onRemoveAutomaticMarking(filterSetId: string) {
        const result = await removeMarkingAutomation.mutateAsync(filterSetId);
        showMarkingAutomationMessages(result);
    }

    function showMarkingAutomationMessages(markingAutomationResult?: ActionResult) {
        if (markingAutomationResult?.isSuccessful) {
            SnackbarManager.show(getMarkingAutomationMessagesTranslation('success'));
            return;
        }

        if (markingAutomationResult?.errorCodes && markingAutomationResult?.errorCodes.length > 0) {
            SnackbarManager.showError(
                markingAutomationResult?.errorCodes
                    .map(errorCode => getMarkingAutomationMessagesTranslation(errorCode))
                    .join(' '),
            );

            return;
        }

        SnackbarManager.showError(getMarkingAutomationMessagesTranslation('technicalException'));
    }

    function getFilterName(filterSet: FilterSet): string {
        if (filterSet.id === defaultFilterSetId) {
            const defaultText = t(`${namespaces.supply}:filterSetOverview.default`);
            return `${filterSet.name} ${defaultText}`;
        }

        return filterSet.name;
    }

    useEffect(() => {
        setDefaultFilterSetId(userSettings.defaultFilterSetId);
    }, [isInitialized]);

    useEffect(() => {
        if (config.intercom.enabled) {
            trackEvent('RFH Pre-Auction', {
                page: 'filterset-overview',
            });
        }
    }, []);

    return (
        <>
            <PageHeader title={t(`${namespaces.supply}:filterSetOverview.header`)} onBackClick={goBack} />
            <OverlayLoadingPage showLoadingOverlay={isLoading}>
                <div className={sharedClasses.flexiblePage}>
                    <div className={classes.addButtonContainer}>
                        <ResponsiveButton
                            className={classes.addButton}
                            variant='outlined'
                            color='primary'
                            icon={<AddIcon />}
                            onClick={() => navigateToFilterSetDetailPage()}
                        >
                            {t(`${namespaces.supply}:filterSetOverview.addFilterSet`)}
                        </ResponsiveButton>
                    </div>
                    <Paper className={classes.paper}>
                        <ResponsiveTable>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell>{t(`${namespaces.supply}:filterSetOverview.name`)}</TableCell>
                                    {markingAutomationsVisible && (
                                        <TableCell className={classes.markingCellWidth}>
                                            {t(`${namespaces.supply}:filterSetOverview.hasMarkingAutomation`)}
                                        </TableCell>
                                    )}
                                    <TableCell className={classes.buttonCellWidth}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {filterSets?.map((filterSet: FilterSet) => (
                                    <TableRow
                                        key={filterSet.id}
                                        className={classes.filterSetRow}
                                        data-testid='filterSetRow'
                                        onContextMenu={contextMenuHandler(contextMenuRef)}
                                    >
                                        <TableCell
                                            className={classes.nameCell}
                                            onClick={() => navigateToFilterSetDetailPage(filterSet.id)}
                                        >
                                            {getFilterName(filterSet)}
                                        </TableCell>
                                        {markingAutomationsVisible && (
                                            <TableCell className={classes.markingCellWidth}>
                                                <div className={classes.checkboxContainer}>
                                                    {filterSet.hasMarkingAutomation && (
                                                        <CheckIcon
                                                            className={classes.checkboxIcon}
                                                            data-testid='markingAutomation'
                                                        />
                                                    )}
                                                </div>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <div className={classes.optionsContainer}>
                                                <IconButton
                                                    className={classes.deleteIconContainer}
                                                    onClick={() => onOpenDeleteFilterSetDialog(filterSet)}
                                                >
                                                    <DeleteIcon
                                                        className={classes.deleteIcon}
                                                        data-testid='deleteFilterSet'
                                                    />
                                                </IconButton>
                                                <ContextMenu
                                                    data-test='contextMenu'
                                                    toggleButtonClassName={classes.contextMenuContainer}
                                                    ref={contextMenuRef}
                                                >
                                                    <ContextMenuItem
                                                        label={t(`${namespaces.general}:buttons.edit`)}
                                                        icon={
                                                            <EditIcon
                                                                className={classes.iconFontSize}
                                                                data-testid='editFilterSet'
                                                            />
                                                        }
                                                        action={() => navigateToFilterSetDetailPage(filterSet.id)}
                                                    />
                                                    <ContextMenuItem
                                                        label={t(`${namespaces.general}:buttons.delete`)}
                                                        icon={<DeleteIcon className={classes.iconFontSize} />}
                                                        action={() => onOpenDeleteFilterSetDialog(filterSet)}
                                                    />
                                                    {filterSet.id !== defaultFilterSetId && (
                                                        <ContextMenuItem
                                                            label={t(`${namespaces.general}:buttons.default`)}
                                                            icon={<StarIcon className={classes.iconFontSize} />}
                                                            action={() => onSetDefault(filterSet.id)}
                                                        />
                                                    )}
                                                    {filterSet.id === defaultFilterSetId && (
                                                        <ContextMenuItem
                                                            label={t(`${namespaces.general}:buttons.removeDefault`)}
                                                            icon={<StarFilledIcon className={classes.iconFontSize} />}
                                                            action={() => onSetDefault(undefined)}
                                                        />
                                                    )}
                                                    {markingAutomationsVisible && !filterSet.hasMarkingAutomation && (
                                                        <ContextMenuItem
                                                            label={t(`${namespaces.general}:buttons.automaticMarking`)}
                                                            icon={<AutoFixNormal className={classes.iconFontSize} />}
                                                            action={() => onSaveAutomaticMarking(filterSet.id)}
                                                        />
                                                    )}
                                                    {markingAutomationsVisible && filterSet.hasMarkingAutomation && (
                                                        <ContextMenuItem
                                                            label={t(
                                                                `${namespaces.general}:buttons.removeAutomaticMarking`,
                                                            )}
                                                            icon={<AutoFixOff className={classes.iconFontSize} />}
                                                            action={() => onRemoveAutomaticMarking(filterSet.id)}
                                                        />
                                                    )}
                                                </ContextMenu>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </ResponsiveTable>
                    </Paper>
                </div>
            </OverlayLoadingPage>

            <DeleteFilterSetDialog
                showDialog={openDeleteFilterSetDialog}
                filterSetName={selectedFilterSet?.name ?? ''}
                onCloseDialog={onCloseDeleteFilterSetDialog}
                onDelete={deleteSelectedFilterSet}
                onDialogClosed={() => setSelectedFilterSet(null)}
            />
        </>
    );
}
