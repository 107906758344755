import { SearchInput } from '@floriday/floriday-ui';
import { Grid2 as Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { INPUT_DEBOUNCE_TIME } from '@constants/debounceConstants';
import { namespaces } from '@root/i18n';
import { useStyles } from './SearchBlockStyles';
import { CharacterButtonToolbar } from '../CharacterButtontoolbar/CharacterButtonToolbar';

interface IProps {
    readonly headerCharacters: { key: string; index: number }[];
    readonly onSearchChange: (search: string) => void;
    readonly jumpToIndex: (index: number) => void;
    readonly showSearchToolbar: boolean;
    readonly showCharacterButtonToolbar: boolean;
}

export default function SearchBlock(props: IProps) {
    const { headerCharacters, onSearchChange, jumpToIndex, showSearchToolbar, showCharacterButtonToolbar } = props;
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'filterItemsSelector' });

    return (
        <Grid container size={{ xs: 12 }}>
            {showSearchToolbar && (
                <>
                    <Grid size={{ xs: 12 }}>
                        <Typography className={classes.text}>{t('searchTitle')}</Typography>
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: showCharacterButtonToolbar ? 3 : 12 }}
                        pt={1}
                        pr={showCharacterButtonToolbar ? 1 : 0}
                    >
                        <SearchInput
                            value=''
                            onChange={onSearchChange}
                            placeholder={t('searchPlaceholder')}
                            debounce={INPUT_DEBOUNCE_TIME}
                            InputProps={{ style: { width: '100%' } }}
                        />
                    </Grid>
                </>
            )}
            {showCharacterButtonToolbar && (
                <Grid size={{ xs: 9 }} pt={1} className={classes.alphabetContainer}>
                    <>
                        <Typography className={classes.text}>{t('jumpTo')}</Typography>
                        <CharacterButtonToolbar characters={headerCharacters} onCharacterSelect={jumpToIndex} />
                    </>
                </Grid>
            )}
        </Grid>
    );
}
