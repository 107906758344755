import { ReactNode } from 'react';
import { Grid2 as Grid } from '@mui/material';
import {
    FilterOption,
    SupplyFilterType,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { v4 as uuidv4 } from 'uuid';

import { FilterPanelSetting, filterPanelSettings } from '@constants/filterPanelSettings';
import StatefulCheckbox from '@features/Filtering/Components/StatefulCheckbox/StatefulCheckbox';
import { MainColorIcon } from '@root/Components/MainColorIcon/MainColorIcon';
import MainGroupIcon from '@root/Components/MainGroupIcon/MainGroupIcon';
import { getColorTranslations } from '@utils/TranslationsUtils';
import { useStyles } from './FilterItemStyles';

export interface IProps {
    readonly item: FilterOption;
    readonly selected: boolean;
    readonly showCount?: boolean;
    readonly showFilterOptionCode?: boolean;
    readonly onToggle: (item: FilterOption, checked: boolean) => void;
    readonly filterType: SupplyFilterType;
}

export default function FilterItem(props: IProps) {
    const { item, selected, onToggle, showCount, showFilterOptionCode, filterType } = props;
    const { classes } = useStyles();
    const uniqueFilterId = uuidv4();

    function getText(item: FilterOption): string {
        if (filterType === SupplyFilterType.MainColor) return getColorTranslations(item.name);
        if (showFilterOptionCode) return `${item.name} (${item.code?.trim()})`;
        return item.name;
    }

    function getIcon(): ReactNode {
        if (filterPanelSettings[filterType]?.includes(FilterPanelSetting.showMainGroupIcon))
            return <MainGroupIcon code={item.code ?? ''} />;
        if (filterType === SupplyFilterType.MainColor) return <MainColorIcon code={item.code ?? ''} />;
        return undefined;
    }

    return (
        <Grid
            size={{ xs: 12, md: 6 }}
            className={classes.filterItemContainer}
            data-testid={`${getText(item)[0]}-filterItem`}
        >
            <div className={classes.filterItem}>
                <StatefulCheckbox
                    id={`${uniqueFilterId}-${item.key}-checkbox`}
                    text={getText(item)}
                    checked={selected}
                    checkChanged={checked => onToggle(item, checked)}
                    icon={getIcon()}
                />
            </div>
            {showCount && (
                <div data-testid='counter' className={classes.filterItemCount}>
                    ({item.searchHitCount})
                </div>
            )}
        </Grid>
    );
}
